<script setup lang="ts">
const view: Ref<paper.View> | undefined = inject('paper.view');
const lastViewCenter = ref();

const { mouseDragHandler, mouseMoveHandler, mouseDownHandler } = usePaperCanvasHandlers({ view, lastViewCenter });

onMounted(async () => {
    await nextTick();

    lastViewCenter.value = view.value.center;
    view.value.onMouseDown = mouseDownHandler;
    view.value.onMouseMove = mouseMoveHandler;
    view.value.onMouseDrag = mouseDragHandler;
});
</script>

<template>
    <slot />
</template>
