<script lang="ts" setup>
const active = defineModel('active', { type: Boolean });
const playStore = usePlayStore();

const searchInput = ref(null);
const filter = ref('');

function filterCharacters(character: Character) {
    return character.name.toLowerCase().includes(filter.value.toLowerCase())
        || character.race.toLowerCase().includes(filter.value.toLowerCase())
        || character.gender.toLowerCase().includes(filter.value.toLowerCase());
}

const filteredCharacters = computed(() => playStore.characters.filter(filterCharacters));
</script>

<template>
    <ModalBase
        v-model:active="active"
        :loading-condition="playStore.loadingPDFs"
        :initial-focus="searchInput"
        full-height
        full-width
    >
        <template #title>
            Wähle deinen Charakter
        </template>
        <div class="search mb-4">
            <input
                ref="searchInput"
                v-model="filter"
                class="border-gray-400 border rounded px-2 py-1 block w-64"
                placeholder="Charaktere suchen..."
                type="text"
            >
        </div>
        <div
            class="characters grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 pb-4"
        >
            <template v-if="filteredCharacters.length">
                <template
                    v-for="character in filteredCharacters"
                    :key="character.ID"
                >
                    <CharacterBox
                        :character
                        @click="
                            playStore.loadPDF({
                                element: character,
                                type: PDFTYPE.CHARACTER,
                                broadcast: true,
                            })
                        "
                    />
                </template>
            </template>
            <template v-else>
                <p class="col-span-full">
                    Kein Charakter entspricht deinen Kriterien.
                </p>
            </template>
        </div>
    </ModalBase>
</template>
