<script lang="ts" setup>
const session = useMultiplayerSessionStore();

const copyLinkRef = ref<HTMLInputElement | null>(null);

const active = defineModel('active', { type: Boolean });

const { copy, copied } = useClipboard();

const route = useRoute();
const fullUrl = computed(() => new URL(route.fullPath, window.location.origin).href);

async function copyLink() {
    copyLinkRef.value?.select();
    await copy(fullUrl.value);
}
</script>

<template>
    <ModalBase
        v-model:active="active"
        :loading-condition="!session.roomUuid"
    >
        <template #title>
            Freunde einladen
        </template>
        <p class="mb-2">
            Teile diesen Link mit anderen, um sie ins Spiel einzuladen.
        </p>
        <div class="flex">
            <button
                ref="copyButton"
                class="btn w-32 p-2 bg-black transition-colors hover:bg-gray-800 text-white"
                @click="copyLink"
            >
                {{ copied ? 'Kopiert!' : 'Kopieren' }}
            </button>
            <input
                ref="copyLinkRef"
                v-model="fullUrl"
                class="focus:outline-none w-full p-2 border border-gray-600"
                readonly
                type="text"
            >
        </div>
        <small>Tipp: Du kannst den Link auch direkt aus der Adressleiste deines Browsers kopieren.</small>
    </ModalBase>
</template>
