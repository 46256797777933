import { useStorage } from '@vueuse/core';

export const getJwt = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const jwtFromUrl = searchParams.get('_jwt');
    // Use the nonce from the URL if it exists, otherwise fallback on local storage
    const jwtFromStorage = useStorage('jwt', '');
    const jwt = jwtFromUrl || jwtFromStorage.value || '';
    // Only update the history and local storage if we got the nonce from the URL
    if (jwtFromUrl) {
        searchParams.delete('_jwt');
        history.replaceState(null, '', `${window.location.pathname}?${searchParams}`);
        jwtFromStorage.value = jwt;
    }
    return jwt;
};
