export default defineStore('gestures', () => {
    const isPinching = ref(false);
    const pinchingJustEnded = ref(false);

    function pinchStart() {
        isPinching.value = true;
    }

    async function pinchEnd() {
        isPinching.value = false;
        pinchingJustEnded.value = true;
        await wait(50);
        pinchingJustEnded.value = false;
    }

    return {
        isPinching,
        pinchingJustEnded,
        pinchStart,
        pinchEnd,
    };
});
