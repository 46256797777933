function calculateAngle(mouseEvent: MouseEvent) {
    const { width, height } = useWindowSize();
    const centerX = computed(() => width.value / 2);
    const centerY = computed(() => height.value / 2);

    const diffX = mouseEvent.clientX - centerX.value;
    const diffY = mouseEvent.clientY - centerY.value;

    // Calculate the angle
    let angle = Math.atan2(diffY, diffX) * (180 / Math.PI);

    // Convert negative degrees to positive
    if (angle < 0) {
        angle = 360 + angle;
    }
    return angle;
}

const { sourceType } = useMouse();

export function usePaperCanvasHandlers({ view, lastViewCenter }: { view: Ref<paper.View>; lastViewCenter: Ref<any> }) {
    const playStore = usePlayStore();
    const backendService = useBackendService();
    const lastMousePoint = computed(() => playStore.lastMousePoint);
    const viewStartRotation = ref(0);
    const startingAngle = ref(0);
    const eventStartCoordinate = ref({ x: 0, y: 0 });

    function mouseDragHandler(event) {
        lastViewCenter.value = unref(view).center;
        if (event.event.buttons == 1 || event.event.buttons == 4 || sourceType.value === 'touch') {
            view.value.center = unref(view).center.add(
                lastMousePoint.value.subtract(event.point),
            );
        }
        else if (event.event.buttons == 2) {
            const currentAngle = calculateAngle(event.event);
            const relativeAngle = currentAngle - startingAngle.value;
            view.value.rotation = viewStartRotation.value + relativeAngle;
            lastViewCenter.value = unref(view).center;
        }
    }

    function mouseMoveHandler(event) {
        const mousePosition = 'mp';
        backendService.broadcastVolatileThrottled({
            event: mousePosition,
            payload: {
                x: event.point.x,
                y: event.point.y,
            },
        });
    }

    function mouseDownHandler(event) {
        playStore.setLastMousePoint(event.point.add(unref(view).center.subtract(lastViewCenter.value)));
        if (event.event.buttons == 1) {
            logClick(event.point);
        }
        lastViewCenter.value = unref(view).center;
        viewStartRotation.value = unref(view).rotation;
        startingAngle.value = calculateAngle(event.event);
        eventStartCoordinate.value = event.point;
    }

    return {
        mouseDragHandler,
        mouseMoveHandler,
        mouseDownHandler,
    };
}
