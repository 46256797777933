export interface ZoomStrategyParameters {
    zoomTarget: MaybeRef<number>;
    view: Ref<paper.View>;
    increments: MaybeRef<number>;
    maxZoom: MaybeRef<number>;
    minZoom: MaybeRef<number>;
}

type ZoomStrategy = (params: ZoomStrategyParameters) => void;

export const smoothlyGoToTargetZoomLevel: ZoomStrategy = ({ zoomTarget, view, maxZoom, minZoom, increments }: ZoomStrategyParameters) => {
    const threshold = 0.05;
    if (Math.abs(unref(zoomTarget) - unref(view).zoom) < threshold) return;

    let change = unref(increments);
    if (unref(zoomTarget) < unref(view).zoom) {
        change = -change;
    }

    // const previousZoomLevel = view.value.zoom;
    const progress = Math.abs(unref(zoomTarget) - unref(view).zoom) / (unref(maxZoom) - unref(minZoom));
    const smoothedChange = smoothstep(0, 1, progress) * change;
    view.value.zoom += smoothedChange;
};

export const jumpToTargetZoomLevel: ZoomStrategy = ({ zoomTarget, view }: ZoomStrategyParameters) => {
    view.value.zoom = unref(zoomTarget);
};
