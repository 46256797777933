export default function () {
    const backendService = useBackendService();
    const playStore = usePlayStore();

    function generateEvents(element) {
        const {
            canMoveItem,
            canRotateItem,
            rotatingItem,
            movingItem,
            clickOnPaperItem,
        } = usePaperState(element.id);

        const setCursorStyle = style => document.body.style.cursor = style;

        const handleMouseDrag = (event) => {
            /** Movement **/
            if (event.event.buttons === 1 || canMoveItem.value) {
                event.stopPropagation();
                element.position.x += event.delta.x;
                element.position.y += event.delta.y;
                movingItem.value = true;

                backendService.sendPosition(event.target);
            }

            /** Rotation **/
            else if (event.event.buttons === 2 || canRotateItem.value) {
                rotatingItem.value = true;
                event.stopPropagation();

                const center = element.startPosition;
                const degree
                    = (Math.atan2(
                        event.point.y - center.y,
                        event.point.x - center.x,
                    ) * 180)
                    / Math.PI;
                let ang = element.startRotation + degree - element.pressAngle;
                if (event.event.shiftKey) {
                    ang = ((ang / 15) >> 0) * 15;
                }
                // Round to 1/100 of a degree. Precise enough and reduces data sent
                element.rotation = Math.round(ang * 100) / 100;
                backendService.sendPosition(event.target);
            }

            backendService.broadcastVolatileThrottled({
                event: 'mp',
                payload: {
                    x: round(event.point.x),
                    y: round(event.point.y),
                },
            });
        };

        const handleMouseEnter = () => {
            element.hovered = true;
            if (playStore.logClicks) return;
            document.body.style.cursor = 'grab';
        };

        const handleMouseLeave = () => {
            element.hovered = false;
            document.body.style.cursor = 'default';
        };

        const handleMouseDown = (event) => {
            if (event.event.buttons === 1) {
                logClick(event.point);
            }

            element.startCoordinate = event.point;
            element.startPosition = element.position;
            element.startRotation = element.rotation;

            element.pressAngle
                = (Math.atan2(
                    element.startCoordinate.y - element.position.y,
                    element.startCoordinate.x - element.position.x,
                )
                * 180)
                / Math.PI;

            const playStore = usePlayStore();
            playStore.setLastMousePoint(event.point);

            setCursorStyle('grabbing');
            clickOnPaperItem.value = true;
        };

        const handleMouseUp = () => {
            if (element.hovered) {
                setCursorStyle('grab');
            }
            else {
                setCursorStyle('default');
            }
            clickOnPaperItem.value = false;
            movingItem.value = false;
            rotatingItem.value = false;
        };

        return {
            handleMouseUp,
            handleMouseDown,
            handleMouseLeave,
            handleMouseEnter,
            handleMouseDrag,
        };
    }

    return {
        generateEvents,
    };
}
