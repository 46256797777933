<script lang="ts" setup>
import { md5 } from 'js-md5';

const view: Ref<paper.View> | undefined = inject('paper.view');
const players = shallowReactive({});
const playerArray = computed(() => Object.values(players));

const bus = useGameEventBus();
bus.on('new-player', ({ name, from }) => {
    // TODO should get whole player object from server
    players[from] = reactive({
        color: `#${md5(name).slice(0, 6)}`,
        name,
        position: { x: 0, y: 0 },
        socketId: from,
    });
    // TODO color not working properly, not everyone gets color broadcast on join
    console.log(`set color to #${md5(name).slice(0, 6)} by slicing first letters from md5 based on ${name}: ${md5(name)}`);
    // TODO player entered notification
});

bus.on('player-info', ({ from, name }) => {
    players[from].name = name;
});

bus.on('remove-player', ({ from: socketId }) => {
    // Clean up object
    delete players[socketId];

    // TODO player left notification
});

bus.on('mp', (data) => {
    players[data.from].position = {
        x: data.x,
        y: data.y,
    };
});

const { width, height } = useWindowSize();
function getScreenCoordinates(player, view) {
    if (!player) return;
    // Step 1: Calculate relative position to view center
    const relativeX = player.position.x - view.center.x;
    const relativeY = player.position.y - view.center.y;

    // Step 2: Apply the zoom factor
    const zoomedX = relativeX * view.zoom;
    const zoomedY = relativeY * view.zoom;

    // Step 3: Determine the absolute screen pixel coordinate using center
    const screenX = (width.value / 2) + zoomedX;
    const screenY = (height.value / 2) + zoomedY;

    return `${screenX}px, ${screenY}px`;
}
</script>

<template>
    <div
        id="player-cursors"
        class="pointer-events-none fixed top-0 left-0"
    >
        <div
            v-for="player in playerArray"
            :key="player.socketId"
        >
            <div
                class="player fixed top-0 left-0"
                :style="{
                    transform: `translate(${getScreenCoordinates(player, view)})`,
                }"
            >
                <div class="flex items-center gap-1">
                    <div
                        class="w-4 h-4 rounded-full"
                        :style="{ 'background-color': player.color }"
                    />
                    <span :style="{ color: player.color }">
                        {{ player.name }}
                    </span>
                </div>
            </div>
        </div>
        
    </div>
</template>
