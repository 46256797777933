export interface Position2D {
    x: number; y: number;
}

export interface Player {
    socketId: string;
    name: string;
    color: string;
    position: Position2D;
}

export default defineStore('multiplayer-session', () => {
    const route = useRoute();
    const router = useRouter();
    const backendService = useBackendService();

    const roomUuid = ref('');
    const socketId = ref('');
    const connectId = ref('');
    const connectedToBackend = ref(false);

    const allPlayers = ref<Player[]>([]);

    const players = computed(() => allPlayers.value.filter(player => player.socketId !== socketId.value));
    const self = computed(() => allPlayers.value.find(player => player.socketId === socketId.value));

    function addPlayer(player: Player) {
        allPlayers.value.push(player);
    }
    function removePlayer(socketId: string) {
        allPlayers.value = players.value.filter(player => player.socketId != socketId);
    }

    function joinIntoRoom() {
        const roomId = route.query.roomId?.toString() ?? '';
        backendService.joinRoom(roomId);
        connectId.value = roomId;
    }
    whenever(connectedToBackend, joinIntoRoom);

    function addRoomIdToRouteQuery(id) {
        if (!id) return;
        router.replace({ query: { ...route.query, roomId: id } });
    }
    whenever(roomUuid, addRoomIdToRouteQuery);

    return {
        roomUuid,
        socketId,
        connectId,
        connectedToBackend,
        allPlayers,
        players,
        self,
        addPlayer,
        removePlayer,
    };
});
