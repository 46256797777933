<script lang="ts" async setup>
const playStore = usePlayStore();
const player = usePlayerStore();
const backendService = useBackendService();

async function jwtBasedAuthFlow() {
    const { data } = await useRestAPI('user');
    const route = useRoute();
    if (!data.value) {
        const config = useRuntimeConfig();
        window.location.href = config.public.API.LOGIN_PROVIDER_URL + '?_redirectUrl=' + window.location.href;
        await wait(5000);
        return;
    }
    else {
        player.setPlayer({
            ...data.value,
        });
        backendService.broadcast({
            event: 'player-info',
            payload: player.serialized,
        });
        playStore.setLoading(false);
    }

    // Remove the jwt + _nonce from the get parameters
    // TODO remove _nonce logic when v2 is live and _nonce no longer is set by auth provider
    const router = useRouter();
    await router.replace({ query: { ...route.query, message: undefined, _jwt: undefined, _nonce: undefined } });
}

/**
 * We demand the user to be authenticated. This process is facilitated by
 * a separate wordpress page, where we get a jwt and redirect the user to the login if necessary
 * After login, the user is redirected here where the jwt is stored locally and sent with every request.
 */
if (import.meta.client) await jwtBasedAuthFlow();

const jwtModalActive = ref(false);

watch(() => playStore.loadingPDFs, (currentlyLoadingPdfs) => {
    // When false: All PDFs loaded, request their position & rotation
    if (currentlyLoadingPdfs === false && playStore.initialLoad) {
        playStore.setInitialLoad(false);
        backendService.send('request-items-position');
    }
},
);

// Load characters & missions
playStore.loadCharacters();
playStore.loadMissions();

// Prevent normal right click menu from opening;
if (process.env.NODE_ENV == 'production') {
    window.oncontextmenu = function () {
        return false;
    };
}

const { characterSelectModal, missionSelectModal, inviteModal } = storeToRefs(useModalStore());

const { emit } = useGameEventBus();
function rollDice() {
    const result = Math.ceil(Math.random() * 6);
    emit('dice-throw', { result });
    backendService.broadcast({
        event: 'dice-throw',
        payload: { result },
    });
}

// Connect after mounting to avoid timing issues - event listeners are registered in different components
onMounted(backendService.connect);
</script>

<template>
    <div class="relative w-full h-full">
        <ModalInviteFriends v-model:active="inviteModal" />
        <ModalCharacterSelection v-model:active="characterSelectModal" />
        <ModalMissionSelection v-model:active="missionSelectModal" />

        <ClientOnly>
            <PaperCanvas>
                <PaperPlayerCursors />
                <PaperViewCameraControls />
                <PaperZoomAndGestureHandler />
                <PaperElements />
                <PaperGameEvents />
                <KeepAlive>
                    <LazyDiceRoller />
                </KeepAlive>
            </PaperCanvas>
        </ClientOnly>

        <div class="buttons absolute bottom-2 left-2 text-sm">
            <button
                class="block bg-black rounded text-white px-2 py-1 transition-all hover:bg-gray-800"
                @click="inviteModal = true"
            >
                Spieler einladen
            </button>
        </div>

        <div class="buttons absolute top-2 left-2 text-sm">
            
            <button
                class="block bg-black rounded text-white px-2 py-1 transition-all hover:bg-gray-800"
                @click="characterSelectModal = true"
            >
                Charaktere
            </button>

            <button
                class="block bg-black rounded text-white px-2 py-1 mt-2 transition-all hover:bg-gray-800"
                @click="missionSelectModal = true"
            >
                Missionen
            </button>

            <button
                class="mt-2 block"
                @click="rollDice"
            >
                <SvgoDice
                    alt="Würfel Modal öffnen"
                    class="max-w-full w-8 aspect-square cursor-pointer transition-all transform hover:scale-110"
                />
            </button>
        </div>
    </div>
</template>
