import { throttle } from 'throttle-debounce';
import { roundToPrecision } from '~/utils/rounding';

const MOUSE_POSITION_UPDATE_RATE_PER_SECOND = 30;

export function useBackendService() {
    const socket = useSocketIO();

    function connect() {
        socket.connect();
    }

    function joinRoom(roomId) {
        // Try to join into game room
        socket.emit('join-into', roomId);
    }

    const send = (event: string, payload?: any) => {
        return socket.emit(event, payload);
    };

    /**
     * Send an event to every other socket in the same room
     * @param event
     * @param payload
     * @param volatile
     */
    const broadcast = ({ event, payload }: { event: string; payload: object }, volatile = false) => {
        if (volatile) {
            socket.$socket.volatile.emit('broadcast', [event, payload]);
        }
        else {
            socket.emit('broadcast', [event, payload]);
        }
    };

    /**
     * Send an event to every other socket in the same room, don't care if they get it
     * @param event
     * @param payload
     */
    const broadcastVolatile = ({ event, payload }: { event: string; payload: any }) => {
        broadcast({ event, payload }, true);
    };

    const emitToSocket = ({ to, event, payload }: { to: string; event: string; payload?: any }) => {
        const modifiedPayload = payload ? payload : null;
        socket.emit('event-to-socket', [to, event, modifiedPayload]);
    };

    const sendDataThrottled = throttle(
        1000 / MOUSE_POSITION_UPDATE_RATE_PER_SECOND,
        (data: any) => {
            broadcast(data);
        },
    );

    const broadcastVolatileThrottled = throttle(
        1000 / MOUSE_POSITION_UPDATE_RATE_PER_SECOND,
        (data: any) => {
            broadcastVolatile(data);
        },
    );

    const sendPosition = (element: any) => {
        return broadcast({
            event: 'position',
            payload: {
                x: round(element.position.x),
                y: round(element.position.y),
                r: roundToPrecision(element.rotation, 2),
                name: element.name,
            },
        });
    };

    return {
        send,
        sendPosition,
        joinRoom,
        broadcast,
        broadcastVolatile,
        emitToSocket,
        sendDataThrottled,
        broadcastVolatileThrottled,
        socket,
        connect,
    };
}
