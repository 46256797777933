import type { FetchContext } from 'ofetch';
import { getJwt } from '~/utils/get-parameters';

export function useRestAPI<T>(route: string, useNuxtApiLayer?: boolean) {
    const config = useRuntimeConfig();
    const jwt = getJwt();
    const base = config.public.API.BASE;
    const urlStrategy = urlBuilderStrategy(useNuxtApiLayer);
    const fullUrl = urlStrategy(base, route);

    return useFetch<T>(fullUrl, {
        credentials: 'include',
        onRequest(context: FetchContext): Promise<void> | void {
            context.options.headers = context.options.headers || {};

            if (jwt) {
                context.options.headers['Authorization'] = 'Bearer ' + jwt;
                return;
            }

            // if (nonce) context.options.headers["X-WP-Nonce"] = nonce;
        },
    });
}
