<script setup lang="ts">
import { Point } from 'paper';
import type paper from 'paper';
import { captureException } from '@sentry/vue';

const project: Ref<paper.Project | undefined> | undefined = inject('paper.project');

const bus = useGameEventBus();
bus.on('position', (data) => {
    const target = project?.value?.getItem({ name: data.name });
    if (!target) {
        captureException({
            fingerprint: '[DESYNC] Received instructions to move an object that does not exist on the canvas. Might happen when the app was minimised.',
        });
        return console.warn(
            '[DESYNC] Received instructions to move an object that does not exist on the canvas. Might happen when the app was minimised.',
        );
    }
    target.position = new Point(data.x, data.y);
    target.rotation = data.r;
});

bus.on('items-state', (state) => {
    delete state.from;
    updateItemsState(unref(project), Object.values(state));
});
</script>

<template>
    <slot />
</template>
