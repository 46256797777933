import useGestureStore from '~/composables/useGestureStore';

export default function (id: number) {
    const { sourceType } = useMouse();
    const { pressed } = useMousePressed();
    const gestures = useGestureStore();
    const LONG_PRESS_DURATION = 500;
    const longPress = ref(false);
    const clickOnPaperItem = ref(false);
    const rotatingItem = ref(false);
    const movingItem = ref(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const groupId = ref(id);

    const { start, stop } = useTimeoutFn(() => {
        longPress.value = true;
    }, LONG_PRESS_DURATION, { immediate: false });

    watch(pressed, (isPressed) => {
        if (isPressed) start();
        else {
            stop();
            longPress.value = false;
        }
    });

    const canRotateItem = computed(() => longPress.value
        && !movingItem.value
        && !gestures.isPinching
        && !gestures.pinchingJustEnded,
    );
    const canMoveItem = computed(() => !gestures.isPinching
        && ((sourceType.value === 'touch' && longPress.value === false) || movingItem.value));
    const rotatingPaperItem = computed(() => sourceType.value === 'touch' && clickOnPaperItem.value && canRotateItem.value);

    const { vibrate } = useVibrate({ pattern: [75] });

    watch(rotatingPaperItem, () => {
        devOnly(() => console.log('[vibrate]'));
        vibrate();
    });

    return {
        clickOnPaperItem,
        rotatingItem,
        movingItem,
        canRotateItem,
        canMoveItem,
    };
}
