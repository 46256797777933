import mitt from 'mitt';

const bus = mitt();

export default function () {
    let eventList: any[] = [];

    function on(event, callback) {
        bus.on(event, callback);
        eventList.push({ event, callback });
    }

    function emit(event, ...args) {
        bus.emit(event, ...args);
    }

    function off(event, callback) {
        bus.off(event, callback);
        const index = eventList.findIndex(el => el.event === event && el.callback === callback);
        if (index >= 0) eventList.splice(index, 1);
    }

    onUnmounted(() => {
        for (const event of eventList) {
            bus.off(event.event, event.callback);
        }
        eventList = []; // Clear the event list
    });

    return {
        emit,
        on,
        off,
    };
}
