import paper from 'paper';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

const RENDER_SCALE = 2;

function generatePaperObjectsFromDataUrl(
    paper: paper.PaperScope,
    dataUrl,
    clipgroups: any[] | string,
    id,
) {
    const { generateEvents } = usePaperObjectEventHandlerGenerator();

    const pdfLayer = paper.project.layers['pdfs'] as paper.Layer;
    pdfLayer.activate();
    const raster = new paper.Raster(dataUrl);

    function generateUniqueGroupName(i, group) {
        // Do a regex to create a unique name for the group that uses the amount of groups with that name as identifier
        const regex = new RegExp('g_' + i + '_' + id + '_*', 'g');
        const amountOfElementsWithThatName = pdfLayer.getItems(
            { name: regex },
        )?.length;
        group.name = 'g_' + i + '_' + id + '_' + amountOfElementsWithThatName;
    }

    raster.onLoad = () => {
        raster.position.set(raster.width / 2, raster.height / 2);

        const group = new paper.Group();
        const elements: paper.Group[] = [];
        if (clipgroups) {
            if (typeof clipgroups === 'string' && clipgroups.length > 0) {
                clipgroups = JSON.parse(clipgroups);
            }
            let i = 0;
            (clipgroups as any[]).forEach((clipgroup) => {
                const clip = new paper.Path(clipgroup);
                clip.closed = true;
                // Create stroke
                const stroke = clip.clone();
                stroke.strokeColor = new paper.Color(0, 0, 0);
                stroke.strokeWidth = 1;
                stroke.dashArray = [4, 6];

                // Create group with clipgroup, image and draw stroke on top
                const group = new paper.Group([clip, raster.clone(), /* handleElement, */ stroke]);
                group.clipped = true;
                // If true, transformations are baked into the element, making absolute rotations impossible
                group.applyMatrix = false;
                generateUniqueGroupName(i, group);

                // Add group to elements array
                elements.push(group);
                i++;
            });
        }
        // No clipgroups defined = take complete page
        else {
            const group = new paper.Group(raster.clone());
            group.applyMatrix = false;
            generateUniqueGroupName(0, group);
            elements.push(group);
        }
        elements.forEach((element) => {
            // Attach event handlers
            const objectEventHandlers = generateEvents(element);
            element.onMouseDrag = objectEventHandlers.handleMouseDrag;
            element.onMouseEnter = objectEventHandlers.handleMouseEnter;
            element.onMouseLeave = objectEventHandlers.handleMouseLeave;
            element.onMouseDown = objectEventHandlers.handleMouseDown;
            element.onMouseUp = objectEventHandlers.handleMouseUp;
            group.addChild(element);
        });

        const playStore = usePlayStore();
        if (!playStore.logClicks) {
            group.scale(0.5);
        }
        raster.remove();
    };
};

const renderPageIntoCanvasDataURL = async (page) => {
    const viewport = page.getViewport({ scale: RENDER_SCALE });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    await page.render({
        canvasContext: context,
        viewport: viewport,
    }).promise;

    return canvas.toDataURL();
};

async function loadDocument(url) {
    if (!GlobalWorkerOptions.workerSrc) {
        GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');
    }
    return await getDocument(url).promise;
}

const loadCharacterPDFIntoDataURL = async (url) => {
    const pdf = await loadDocument(url);
    const page = await pdf.getPage(1);
    return renderPageIntoCanvasDataURL(page);
};

const loadMissionPDFIntoDataURLsWithClipgroups = async (mission) => {
    const pdf = await loadDocument(mission.url);
    const dataURLsWithClipgroups = [];

    // "Reverse" for loop so the draw order of pages is correct
    for (let i = pdf.numPages; i >= 1; i--) {
        const page = await pdf.getPage(i);
        const dataUrl = await renderPageIntoCanvasDataURL(page);
        const clipgroup = mission.clipgroups?.find(
            clipgroup => clipgroup.pageNumber == i,
        )?.clipgroups;
        const dataUrlWithClipgroup = {
            dataUrl,
            clipgroup,
        };
        dataURLsWithClipgroups.push(dataUrlWithClipgroup);
    }
    return dataURLsWithClipgroups;
};

export const loadPDFIntoCanvas = async (pdfObject, type) => {
    if (type === PDFTYPE.CHARACTER) {
        const dataUrl = await loadCharacterPDFIntoDataURL(pdfObject.url);
        generatePaperObjectsFromDataUrl(
            paper,
            dataUrl,
            pdfObject.clipgroups,
            pdfObject.ID,
        );
    }
    else if (type === PDFTYPE.MISSION) {
        const dataUrlsWithClipgroups = await loadMissionPDFIntoDataURLsWithClipgroups(
            pdfObject,
        );
        dataUrlsWithClipgroups.forEach((dataUrlWithClipgroup) => {
            generatePaperObjectsFromDataUrl(
                paper,
                dataUrlWithClipgroup.dataUrl,
                dataUrlWithClipgroup.clipgroup,
                pdfObject.ID,
            );
        });
    }
};
