<script setup lang="ts">
const active = defineModel('active', { type: Boolean });
const playStore = usePlayStore();

const searchInput = ref(null);
const filter = ref('');

function filterMissions(mission) {
    return mission.subtitle.toLowerCase().includes(filter.value.toLowerCase())
        || mission.name.toLowerCase().includes(filter.value.toLowerCase())
        || mission.excerpt.toLowerCase().includes(filter.value.toLowerCase());
}

const filteredMissions = computed(() => playStore.missions.filter(filterMissions));
</script>

<template>
    <ModalBase
        v-model:active="active"
        :loading-condition="playStore.loadingPDFs"
        :initial-focus="searchInput"
        full-width
        full-height
    >
        <template #title>
            Wählt eure Mission
        </template>
        <div class="search mb-4">
            <input
                ref="searchInput"
                v-model="filter"
                class="border-gray-400 border rounded px-2 py-1 block w-64"
                placeholder="Mission suchen..."
                type="text"
            >
        </div>
        <div
            class="missions grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 pb-4"
        >
            <template v-if="filteredMissions.length">
                <template
                    v-for="mission in filteredMissions"
                    :key="mission.ID"
                >
                    <MissionBox
                        :mission
                        @click="
                            playStore.loadPDF({
                                element: mission,
                                type: PDFTYPE.MISSION,
                                broadcast: true,
                            })
                        "
                    />
                </template>
            </template>
            <template v-else>
                <p class="col-span-full">
                    Keine Mission entspricht deinen Kriterien.
                </p>
            </template>
        </div>
    </ModalBase>
</template>
