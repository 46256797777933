export function findAngle(
    p0: { x: number; y: number },
    p1: { x: number; y: number },
    p2: { x: number; y: number },
) {
    const a = Math.pow(p1.x - p0.x, 2) + Math.pow(p1.y - p0.y, 2),
        b = Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2),
        c = Math.pow(p2.x - p0.x, 2) + Math.pow(p2.y - p0.y, 2);
    return Math.acos((a + b - c) / Math.sqrt(4 * a * b));
}

const clipgroup: any = [];
const clipgroupStrings: string[] = [];

export function logClick(point: { x: number; y: number }) {
    const playStore = usePlayStore();
    if (playStore.logClicks) {
        console.log('[' + Math.round(point.x) + ', ' + Math.round(point.y) + '],');
        clipgroup.push(point);
    }
}

/** clipgroup generation functionality
 *  - Make sure logClicks in main.ts is enabled.
 *  - PAY ATTENTION TO ORDER! First characters, then hp markers, then wounded markers
 *  - (HP) Marker: Click on upper left corner of marker and press 'm'
 *  - Wounded Marker: Click on upper left corner of wounded marker and press 'w'
 *  - Character Marker: Click on upper left center ( 9:30 o'clock) and press 'c'
 *  - For squares, click upper left + lower right corner, then press space
 *  - For custom shapes, click on each point and press space after you're done
 *  - Press shift + space to copy all clipgroups to clipboard
 */
/*
window.addEventListener("keydown", function(e) {
  if (e.key === " " && e.ctrlKey) {
    clipgroupStrings = [];
    clipgroup = [];
  } else if (e.key === " " && e.shiftKey) {
    console.log(clipgroupStrings);
    console.log("Copied all clipgroups to clipboard.");
    let allClipGroupsAsString =
      "[" + clipgroupStrings.map(string => string).join("") + "]";

    console.log(allClipGroupsAsString);
    allClipGroupsAsString =
      allClipGroupsAsString.substr(0, allClipGroupsAsString.length - 2) + "]";

    const element = document.createElement("input");
    document.body.appendChild(element);
    element.value = allClipGroupsAsString;
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand("copy");
    element.remove();
    clipgroupStrings = [];
    clipgroup = [];
  } else if (e.key === " ") {
    let clipgroupString = "";
    // Create rectangle from two points
    if (clipgroup.length === 2) {
      clipgroupString = `[[${Math.round(clipgroup[0].x)}, ${Math.round(
        clipgroup[0].y
      )}],[${Math.round(clipgroup[0].x)}, ${Math.round(
        clipgroup[1].y
      )}],[${Math.round(clipgroup[1].x)}, ${Math.round(
        clipgroup[1].y
      )}],[${Math.round(clipgroup[1].x)}, ${Math.round(clipgroup[0].y)}]],`;
    } else {
      clipgroupString =
        "[" +
        clipgroup.map(
          (point: { x: number; y: number }) =>
            "[" + Math.round(point.x) + ", " + Math.round(point.y) + "]"
        ) +
        "],";
    }
    //console.log(clipgroupString);
    clipgroupStrings.push(clipgroupString);
    clipgroup = [];
  }
  // Marker
  else if(e.key === "m") {
      const clipgroupString =
      `[[${Math.round(clipgroup[0].x)}, ${Math.round(
          clipgroup[0].y
      )}],[${Math.round(clipgroup[0].x)}, ${Math.round(
          clipgroup[0].y + 72
      )}],[${Math.round(clipgroup[0].x + 228)}, ${Math.round(
          clipgroup[0].y + 72
      )}],[${Math.round(clipgroup[0].x + 241)}, ${Math.round(
          clipgroup[0].y + 38
      )}],[${Math.round(clipgroup[0].x + 228)}, ${Math.round(clipgroup[0].y)}]],`;

      clipgroupStrings.push(clipgroupString);
      clipgroup = [];
  }

  // Wounded square marker
  else if(e.key === "s" || e.key === "w") {
      const clipgroupString =
          `[[${Math.round(clipgroup[0].x)}, ${Math.round(
              clipgroup[0].y
          )}],[${Math.round(clipgroup[0].x)}, ${Math.round(
              clipgroup[0].y + 184
          )}],[${Math.round(clipgroup[0].x + 184)}, ${Math.round(
              clipgroup[0].y + 184
          )}],[${Math.round(clipgroup[0].x + 184)}, ${Math.round(clipgroup[0].y)}]],`;

      clipgroupStrings.push(clipgroupString);
      clipgroup = [];
  }

  // Character marker
  else if(e.key === "c") {
      const clipgroupString =
          `[[${Math.round(clipgroup[0].x)}, ${Math.round(
              clipgroup[0].y
          )}],[${Math.round(clipgroup[0].x)}, ${Math.round(
              clipgroup[0].y + 216
          )}],[${Math.round(clipgroup[0].x + (705 - 554))}, ${Math.round(
              clipgroup[0].y + (637 - 267)
          )}],[${Math.round(clipgroup[0].x + (924 - 554))}, ${Math.round(
              clipgroup[0].y + (637 - 267)
          )}],[${Math.round(clipgroup[0].x + (1079 - 554))}, ${Math.round(
              clipgroup[0].y + (484 - 267)
          )}],[${Math.round(clipgroup[0].x + (1079 - 554))}, ${Math.round(
              clipgroup[0].y
          )}],[${Math.round(clipgroup[0].x + (924 - 554))}, ${Math.round(
              clipgroup[0].y + (112 - 267)
          )}],[${Math.round(clipgroup[0].x + (706 - 554))}, ${Math.round(clipgroup[0].y + (112 - 267))}]],`;

      clipgroupStrings.push(clipgroupString);
      clipgroup = [];
  }
});
*/
export const generateItemState = (project: Ref<paper.Project> | Ref<undefined>): ItemState => {
    const regex = new RegExp('g_*', 'g');
    return (unref(project).layers['pdfs'] as paper.Layer)
        ?.getItems({ name: regex })
        ?.map(({ position, rotation, name }: any) => {
            return {
                position: { x: position.x, y: position.y },
                rotation,
                name,
            };
        });
};

export const updateItemsState = (project: paper.Project, state: ItemState[]) => {
    state.forEach((itemState: ItemState) => {
        const item = (project.layers['pdfs'] as paper.Layer).getItem({ name: itemState.name });
        if (item) {
            item.position = itemState.position;
            item.rotation = itemState.rotation;
        }
        else {
            console.warn('[DESYNC] Received item state for a non-existent item.');
        }
    });
};
