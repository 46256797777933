<script setup lang="ts">
/**
 * This component handles paper js view transforms like zoom and - in case of mobile devices - rotation.
 * Mobile touch gestures powered by vueuse/gestures.
 */
import type paper from 'paper';
import { usePinch } from '@vueuse/gesture';
import useGestureStore from '~/composables/useGestureStore';

const paperView = inject<Ref<paper.View | undefined>>('paper.view');
const paperCanvas = inject<Ref<HTMLCanvasElement | undefined>>('paper.canvas');

const gestures = useGestureStore();

const zoomTarget = ref(1);
const maxZoom = 4.0;
const minZoom = 0.15;

let zoomStrategy = smoothlyGoToTargetZoomLevel;

const zoomOnPinchStart = ref(1);
const zoomThreshold = 45;
const zoomThresholdReached = ref(false);
const zoomThresholdCompensation = ref(0);
const rotationOnPinchStart = ref(0);
const rotationThreshold = 5;
const rotationThresholdReached = ref(false);
const rotationThresholdCompensation = ref(0);
const enoughDistanceForRotationToBeActivated = ref(false);

// Heuristic: Dragging is done with 2 fingers close to each other, block rotation if distance between points is small
const minimumDistanceForRotation = 150;

function pinchHandler({ movement: [deltaDistance, deltaAngle], da: [absoluteDistance], first, last }) {
    if (!paperView?.value) return;

    // initialize values on pinch start
    if (first) {
        enoughDistanceForRotationToBeActivated.value = absoluteDistance > minimumDistanceForRotation;
        zoomOnPinchStart.value = paperView.value.zoom;
        rotationOnPinchStart.value = paperView.value.rotation;
        gestures.pinchStart();
        zoomStrategy = jumpToTargetZoomLevel;
    }

    // Zoom threshold handling
    if (Math.abs(deltaDistance) >= zoomThreshold && !zoomThresholdReached.value) {
        zoomThresholdReached.value = true;
        zoomThresholdCompensation.value = deltaDistance < 0 ? zoomThreshold : -zoomThreshold;
    }

    // Rotation threshold handling
    if (Math.abs(deltaAngle) >= rotationThreshold && !rotationThresholdReached.value) {
        rotationThresholdReached.value = true;
        rotationThresholdCompensation.value = deltaAngle < 0 ? rotationThreshold : -rotationThreshold;
    }

    // Change zoom based on delta + compensation
    if (zoomThresholdReached.value) {
        const change = Math.round(deltaDistance + zoomThresholdCompensation.value) / 400;
        zoomTarget.value = clamp(zoomOnPinchStart.value + change, minZoom, maxZoom);
    }

    // Change angle based on delta + compensation
    if (rotationThresholdReached.value && enoughDistanceForRotationToBeActivated.value) {
        const change = deltaAngle + rotationThresholdCompensation.value;
        paperView.value.rotation = rotationOnPinchStart.value + change;
    }

    if (last) {
        gestures.pinchEnd();
        zoomStrategy = smoothlyGoToTargetZoomLevel;
        zoomThresholdReached.value = false;
        rotationThresholdReached.value = false;
    }
}

usePinch(pinchHandler, {
    domTarget: paperCanvas,
    eventOptions: {
        passive: true,
    // capture: true,
    },
});

// TODO zoom towards cursor
// at max zoom, target position = cursor
// no movement when zooming out, I think
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const targetPosition = ref({ x: 0, y: 0 });
const increments = 0.5;

function zoomCanvas(e) {
    let change = 0.2;
    if (e.deltaY > 0) {
        change = -change;
    }

    zoomTarget.value = clamp(zoomTarget.value + change, minZoom, maxZoom);
}

// @type ZoomStrategyParameters
const zoomStrategyParams = {
    zoomTarget,
    view: paperView,
    increments,
    maxZoom,
    minZoom,
};

useRafFn(() => zoomStrategy(zoomStrategyParams));

const bus = useGameEventBus();
bus.on('zoom', zoomCanvas);
</script>

<template>
    <slot />
</template>
