<script setup lang="ts">
import paper from 'paper';

const view = ref<paper.view | null>(null);
const project = ref<paper.project | null>(null);
const canvas = ref<HTMLCanvasElement | null>(null);

const playStore = usePlayStore();
const bus = useGameEventBus();

onMounted(() => {
    if (window.document.paper) {
        window.document.paper.project.remove();
        document.paper = null;
    }

    paper.install(window.document);

    paper.setup(canvas.value);
    project.value = paper.project;
    playStore.paper = paper;
    new paper.Layer({
        name: 'pdfs',
    });
    new paper.Layer({
        name: 'cursors',
    });

    view.value = project.value.view;
});

provide('paper.project', project);
provide('paper.view', view);
provide('paper.canvas', canvas);

const zoomEvent = e => bus.emit('zoom', e);
</script>

<template>
    <div class="h-full w-full">
        <canvas
            id="gameCanvas"
            ref="canvas"
            resize="true"
            keepalive="true"
            @wheel="zoomEvent"
        />
        <slot />
    </div>
</template>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
  background-color: #eee;
}
</style>
