// Define a higher-order function that accepts a strategy for combining the URL.
function createUrlBuilder(strategy) {
    return function (base, route) {
        return strategy(base, route);
    };
}

// Define a strategy that combines the URL without the Nuxt API layer.
function combineWithoutNuxtApiLayer(base, route) {
    const baseUrl = base.endsWith('/') ? base.slice(0, -1) : base;
    const routeUrl = route.startsWith('/') ? route.slice(1) : route;
    const combinedUrl = `${baseUrl}/${routeUrl}`;
    return combinedUrl.replace('/api', '');
}

// Define a strategy that returns the route as is, assuming the Nuxt API layer is used.
function returnRouteAsIs(base, route) {
    return route;
}

// Create a URL builder instance for each strategy.
export const buildUrlWithoutNuxtApiLayer = createUrlBuilder(combineWithoutNuxtApiLayer);
export const buildUrlWithNuxtApiLayer = createUrlBuilder(returnRouteAsIs);

export const urlBuilderStrategy = (forceNuxtApiLayer?: boolean) => {
    const config = useRuntimeConfig();
    return forceNuxtApiLayer ?? config.public.USE_NUXT_API_LAYER
        ? buildUrlWithNuxtApiLayer
        : buildUrlWithoutNuxtApiLayer;
};
