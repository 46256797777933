<script setup lang="ts">
const { on } = useGameEventBus();
const { emitToSocket, socket } = useBackendService();

const bus = useGameEventBus();

onMounted(() => {
    // Emit every socket event to the event bus, let components / composables modify as needed. Register only once
    if (socket.$socket.listenersAny().length === 0) {
        socket.onAny((event: string, ...args: any) => {
            bus.emit(event, ...args);
        });
    }
});

// Unregister catch all and connect listeners
onBeforeUnmount(() => {
    socket.$socket.offAny();
});

on('set-room-id', (roomId: string) => {
    const session = useMultiplayerSessionStore();
    session.roomUuid = roomId;
    session.addPlayer({
        color: '#000', name: 'Das bist du!', position: { x: 0, y: 0 }, socketId: session.socketId,
    });
});

const project: Ref<paper.Project> = inject('paper.project');
on('request-items-position', ({ from: socketId }) => {
    const state = generateItemState(project);
    emitToSocket({
        to: socketId,
        event: 'items-state',
        payload: state,
    });
});

function sendPlayerInfo({ from: socketId }) {
    const player = usePlayerStore();
    emitToSocket({
        to: socketId,
        event: 'new-player',
        payload: player.serialized,
    });
}

on('request-players', ({ from }) => {
    sendPlayerInfo({ from });
    emitToSocket({
        to: from,
        event: 'request-player',
    });
});

on('request-player', sendPlayerInfo);

on('load-character-pdf', (data) => {
    const playStore = usePlayStore();
    playStore.loadPDF({
        element: data,
        type: PDFTYPE.CHARACTER,
    });
});

on('load-mission-pdf', (data) => {
    const playStore = usePlayStore();
    playStore.loadPDF({
        element: data,
        type: PDFTYPE.MISSION,
    });
});

on('board-status', ({ characters, missions }) => {
    const playStore = usePlayStore();
    characters.forEach((character: Character) => {
        playStore.loadPDF({
            element: character,
            type: PDFTYPE.CHARACTER,
        });
    });
    missions.forEach((mission: Mission) => {
        playStore.loadPDF({
            element: mission,
            type: PDFTYPE.MISSION,
        });
    });
});

const sendCurrentBoardStatus = (socketId: string) => {
    const playStore = usePlayStore();
    const characters = playStore.characters.filter((character: Character) =>
        playStore.activePDFids.find(id => id == character.ID),
    );
    const missions = playStore.missions.filter((mission: Mission) =>
        playStore.activePDFids.find(id => id == mission.ID),
    );
    emitToSocket({
        to: socketId,
        event: 'board-status',
        payload: {
            characters,
            missions,
        },
    });
};
on('request-board-status', sendCurrentBoardStatus);
</script>

<template>
    <slot />
</template>
